import web from "../assets/services/web.jpg";
import marketing from "../assets/services/marketing.jpg";
import video from "../assets/services/video.jpg";
import opic from "../assets/Partners/opic.png";
import mpm from "../assets/Partners/mpm.png";
import audis from "../assets/Partners/audis.png";
import chronovision from "../assets/Partners/chronovision.png";
import essaber from "../assets/Partners/essaber.png";
import electrosky from "../assets/Partners/electrosky.png";
import conquete from "../assets/Partners/conquete.png";
import esgt from "../assets/Partners/esgt.png";
import opicProject from "../assets/projects/opic.png";
import mpmProject from "../assets/projects/MPM.png";

// images services
// import reseaux_sociaux from "../assets/services/reseaux sociaux.jpg";
import reseaux_sociaux from "../assets/services/3-services.webp";
import stratégie from "../assets/services/1-services-2.webp";
import webDev from "../assets/services/2-services-2.webp";
import events from "../assets/services/4-services.webp";

import portfolio_opic from "../assets/projects/opicHomePage.png";
import portfolio_mpm from '../assets/projects/MPM.png';
// OPIC Instagram
import sm_opic_1 from '../assets/portfolio/socialmedia/opic/1.png';
import sm_opic_2 from '../assets/portfolio/socialmedia/opic/2.png';
import sm_opic_3 from '../assets/portfolio/socialmedia/opic/3.png';
import sm_opic_4 from '../assets/portfolio/socialmedia/opic/6.png';
import sm_opic_5 from '../assets/portfolio/socialmedia/opic/7.png';
import sm_opic_6 from '../assets/portfolio/socialmedia/opic/8.png';
import sm_opic_7 from '../assets/portfolio/socialmedia/opic/19.png';
import sm_opic_8 from '../assets/portfolio/socialmedia/opic/27.png';
import sm_opic_9 from '../assets/portfolio/socialmedia/opic/38.png';

// Audis Instagram
import sm_audis_1 from '../assets/portfolio/socialmedia/audis/1.png';
import sm_audis_2 from '../assets/portfolio/socialmedia/audis/3.png';
import sm_audis_3 from '../assets/portfolio/socialmedia/audis/7.png';
import sm_audis_4 from '../assets/portfolio/socialmedia/audis/10.png';
import sm_audis_5 from '../assets/portfolio/socialmedia/audis/16.png';
import sm_audis_6 from '../assets/portfolio/socialmedia/audis/17.png';
import sm_audis_7 from '../assets/portfolio/socialmedia/audis/18.png';
import sm_audis_8 from '../assets/portfolio/socialmedia/audis/19.png';
import sm_audis_9 from '../assets/portfolio/socialmedia/audis/20.png';
import sm_audis_10 from '../assets/portfolio/socialmedia/audis/22.png';
import sm_audis_11 from '../assets/portfolio/socialmedia/audis/23.png';
import sm_audis_12 from '../assets/portfolio/socialmedia/audis/24.png';


// Chronovision  instagram  
import sm_chronocasa_1 from '../assets/portfolio/socialmedia/chronocasa/33.jpg';
import sm_chronocasa_2 from '../assets/portfolio/socialmedia/chronocasa/34.jpg';
import sm_chronocasa_3 from '../assets/portfolio/socialmedia/chronocasa/44.jpg';
import sm_chronocasa_4 from '../assets/portfolio/socialmedia/chronocasa/45.jpg';
import sm_chronocasa_5 from '../assets/portfolio/socialmedia/chronocasa/46.jpg';
import sm_chronocasa_6 from '../assets/portfolio/socialmedia/chronocasa/54.jpg';
import sm_chronocasa_7 from '../assets/portfolio/socialmedia/chronocasa/55.jpg';
import sm_chronocasa_8 from '../assets/portfolio/socialmedia/chronocasa/56.jpg';
import sm_chronocasa_9 from '../assets/portfolio/socialmedia/chronocasa/57.jpg';


// MPM 
import sm_mpm_1 from '../assets/portfolio/socialmedia/mpm/1.png';
import sm_mpm_2 from '../assets/portfolio/socialmedia/mpm/2.png';
import sm_mpm_3 from '../assets/portfolio/socialmedia/mpm/3.png';
import sm_mpm_4 from '../assets/portfolio/socialmedia/mpm/4.png';
import sm_mpm_5 from '../assets/portfolio/socialmedia/mpm/5.png';
import sm_mpm_6 from '../assets/portfolio/socialmedia/mpm/6.png';
import sm_mpm_7 from '../assets/portfolio/socialmedia/mpm/7.png';
import sm_mpm_8 from '../assets/portfolio/socialmedia/mpm/8.png';
import sm_mpm_9 from '../assets/portfolio/socialmedia/mpm/9.png';
import sm_mpm_10 from '../assets/portfolio/socialmedia/mpm/10.png';
import sm_mpm_11 from '../assets/portfolio/socialmedia/mpm/11.png';
import sm_mpm_12 from '../assets/portfolio/socialmedia/mpm/12.png';
import sm_mpm_13 from '../assets/portfolio/socialmedia/mpm/13.png';
import sm_mpm_14 from '../assets/portfolio/socialmedia/mpm/14.png';

// Opic Website
import web_opic_1 from "../assets/projects/opic-list/contact.png";
import web_opic_2 from "../assets/projects/opic-list/contact1.png";
import web_opic_3 from "../assets/projects/opic-list/contact2.png";
import web_opic_4 from "../assets/projects/opic-list/home.png";
import web_opic_5 from "../assets/projects/opic-list/location.png";
import web_opic_6 from "../assets/projects/opic-list/services.png";
import web_opic_home from "../assets/projects/opicHomePage.png";
import web_opic_banner from "../assets/projects/opicBanner.png";

// MPM Website
import web_mpm_home from "../assets/projects/mpm/homeMPM.png";
import web_mpm_1 from "../assets/projects/mpm/products.png";
import web_mpm_2 from "../assets/projects/mpm/protection.png";
import web_mpm_3 from "../assets/projects/mpm/test.png";
// Cherkaoui Website
import web_cherkaoui_long from "../assets/projects/cherkaoui/longform.png";
import web_cherkaoui_c1 from "../assets/projects/cherkaoui/c1.png";
import web_cherkaoui_c2 from "../assets/projects/cherkaoui/c2.png";
import web_cherkaoui_c3 from "../assets/projects/cherkaoui/c3.png";
import web_cherkaoui_c4 from "../assets/projects/cherkaoui/c4.png";
import web_cherkaoui_c5 from "../assets/projects/cherkaoui/c5.png";

// Aroma Luxe
import web_aromaluxe_long from '../assets/projects/aromaluxeLarge1.png';
import web_aromaluxe_1 from '../assets/projects/aromaluxe01.png';
import web_aromaluxe_2 from '../assets/projects/aromaluxe02.png';
import web_aromaluxe_3 from '../assets/projects/aromaluxe03.png';
import web_aromaluxe_4 from '../assets/projects/aromaluxe04.png';
import web_aromaluxe_5 from '../assets/projects/aromaluxe05.png';
import web_aromaluxe_6 from '../assets/projects/aromaluxe06.png';
// Aroma Luxe
import web_HamadElectro_long from '../assets/projects/HamadElectroLarge.png';
import web_HamadElectro_1 from '../assets/projects/HamadElectro01.png';
import web_HamadElectro_2 from '../assets/projects/HamadElectro02.png';
import web_HamadElectro_3 from '../assets/projects/HamadElectro03.png';
import web_HamadElectro_4 from '../assets/projects/HamadElectro04.png';
import web_HamadElectro_5 from '../assets/projects/HamadElectro05.png';
import web_HamadElectro_6 from '../assets/projects/HamadElectro06.png';



import web_annakhil_long from '../assets/projects/annakhilLarge.png';
import web_annakhil_1 from '../assets/projects/annakhil01.png';
import web_annakhil_2 from '../assets/projects/annakhil02.png';
import web_annakhil_3 from '../assets/projects/annakhil03.png';
import web_annakhil_4 from '../assets/projects/annakhil04.png';
import web_annakhil_6 from '../assets/projects/annakhil06.png';
import web_annakhil_7 from '../assets/projects/annakhil07.png';

import web_para_long from '../assets/projects/paraLarge.png';
import web_para_1 from '../assets/projects/para1.png';
import web_para_2 from '../assets/projects/para2.png';
import web_para_3 from '../assets/projects/para3.png';
import web_para_4 from '../assets/projects/para4.png';

import web_para_6 from '../assets/projects/para6.png';
import web_para_7 from '../assets/projects/para7.png';
import web_para_8 from '../assets/projects/para8.png';
import web_para_9 from '../assets/projects/para9.png';


// OPIC PRESENTATION 
import present_opic from '../assets/portfolio/presentation/opic-presentation.png'

// Personal branding
import img_essebar1 from '../assets/portfolio/personal-branding/1-photo-essebar1.png';
import video_essebar1 from '../assets/portfolio/personal-branding/1-video-essebar1-sized.mp4';
import video_essebar2 from '../assets/portfolio/personal-branding/1-vid-essebar-02-sized.mp4';
import video_essebar3 from '../assets/portfolio/personal-branding/1-vid-essebar-03-sized.mp4';

// Audis - AudioVisuel
// import video_audis_1 from '../assets/portfolio/audiovisuel/1-vid-audis-01.mp4';
import video_audis_1 from '../assets/portfolio/audiovisuel/1-vid-audis-01-sized.mp4';
import img_audis_1 from '../assets/portfolio/audiovisuel/1-img-audis-01.png';

// Evomarket - AudioVisuel
import video_evo_1 from '../assets/portfolio/audiovisuel/1-vid-evo-01.mp4';
import img_evo_1 from '../assets/portfolio/audiovisuel/1-img-evo-01.png';
// ESGT - Event
import video_esgt_1 from '../assets/portfolio/events/1-vid-esgt-01-hd.mp4';
import img_esgt_1 from '../assets/portfolio/events/1-img-esgt-01.png';



const services = [
  {
    id: 1,
    name: "Conseil & stratégie",
    image: marketing,
    rol: "Suivi, conseil et élaboration de stratégie",
    img: stratégie,
    description:
      "Suivi, conseil et élaboration de stratégie de communication et de marketing",
    lien: "/conseil-strategie",
  },
  {
    id: 2,
    name: "Développement web",
    rol: "Création de votre site web",
    image: web,
    img: webDev,
    description:
      "Création de sites internet, élaboration et exécution de stratégies digitales",
    lien: "/digital-web",
  },

  {
    id: 3,
    name: "Digital Marketing",
    rol: "Gestion de vos réseaux sociaux et création de votre identité visuelle",
    img: reseaux_sociaux,
    image: video,
    description:
      "Création d'identités visuelles et tout support de communication",
    lien: "/graphique-design",
  },
  {
    id: 4,
    name: "Événementiel",
    rol: "Tournage d'événements, de campagnes publicitaires",
    img: events,
    image: video,
    description:
      "Création d'identités visuelles et tout support de communication",
    lien: "/graphique-design",
  },
];

const partners = [
  {
    name: "Conquete",
    image: conquete,
    message: "",
  },
  {
    name: "Maison Paramed",
    image: mpm,
    message: "",
  },
  {
    name: "Audis",
    image: audis,
    message: "",
  },
  {
    name: "Opic",
    image: opic,
    message: "",
  },
  {
    name: "ESGT",
    image: esgt,
    message: "",
  },
  {
    name: "Chronovision",
    image: chronovision,
    message: "",
  },
  {
    name: "Essebar",
    image: essaber,
    message: "",
  },
  {
    name: "Electrosky",
    image: electrosky,
    message: "",
  },
];
const projects = [
  {
    id: 1,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/webdev/1",
  },
  {
    id: 2,
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/webdev/2",
  },

  {
    id: 4,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  // {
  //   id: 5,
  //   name: "Maison Paramed - Site web Static",
  //   image: mpmProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },
  {
    id: 3,
    name: "Centre Dentaire Cherkaoui",
    image: web_cherkaoui_c1,
    category: "Developpement Web",
    lien: "/webdev/12",
  },
  // {
  //   id: 2,
  //   name: "Opic - Site web Static",
  //   image: opicProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },

  // {
  //   id: 4,
  //   name: "Opic - Site web Static",
  //   image: opicProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },
  // {
  //   id: 5,
  //   name: "Maison Paramed - Site web Static",
  //   image: mpmProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },
];
const testimonials = [
  {
    id: 1,
    company: "Opic Maroc",
    name: "Issam Rahoui",
    post: "Directeur général",
    desc: "Je ne peux que recommander ce service. L'équipe a su répondre à mes besoins de manière rapide et efficace. Leur expertise m'a permis de gagner du temps et d'atteindre mes objectifs bien plus facilement que je ne l'aurais imaginé. Un service professionnel et humain à la fois !",
    image: opic,
  },
  {
    id: 4,
    company: "Maison Paramed",
    name: "Youssef Qd",
    post: "Directeur général ",
    desc: "Le service a été irréprochable du début à la fin. Ils ont pris le temps de comprendre mes besoins, ont proposé des solutions sur mesure et m'ont accompagné tout au long du projet. Je suis ravi du résultat et je n'hésiterai pas à faire de nouveau appel à eux.",
    image: mpm,
  },
  {
    id: 3,
    company: "Audis",
    name: "Fatima Zahra",
    post: "Chef Departement Communication",
    desc: "Je ne peux que recommander ce service. Je suis très satisfaite du service. L'équipe a été à l'écoute, disponible et toujours de bon conseil. Ils ont su anticiper mes attentes et m'ont guidé tout au long du processus. Un service client exemplaire, je recommande sans hésitationmander ce service.",
    image: audis,
  },
  {
    id: 4,
    company: "Essebar Architects",
    name: "Soufiane Essebar",
    post: "Architecte",
    desc: "Une prestation de qualité à tous les niveaux ! Le service a été personnalisé, rapide et extrêmement professionnel. J'ai particulièrement apprécié la réactivité de l'équipe face à mes demandes. C'est rare de trouver un service aussi complet.",
    image: essaber,
  },
  {
    id: 5,
    company: "Chronovision",
    name: "Brahim",
    post: "Responsable Magasin",
    desc: "Le service fourni a surpassé mes attentes. Tout a été parfaitement orchestré, du premier contact à la finalisation du projet. Ils m'ont tenu informé à chaque étape et ont veillé à ce que tout soit conforme à mes attentes. Je suis vraiment impressionné par leur professionnalisme.",
    image: chronovision,

  },
];

const portfolio_assets = [
  {
    id: 1,
    name: "Opic - Site web",
    category: "Developpement web",
    image: portfolio_opic,
    listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
    home: web_opic_home,
    bg: web_opic_banner,
    website: "https://www.opicmaroc.com",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
  },
  {
    id: 2,
    name: "Maison Paramed - Site web",
    category: "Developpement web",
    image: web_mpm_home,
    listImages: [web_mpm_1, web_mpm_2, web_mpm_3],
    home: web_mpm_home,
    bg: web_mpm_home,
    website: "maison-paramed-mar.netlify.app/",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web de Maison Paramed, centre auditif situé à Salé. Ce site a été pensé pour offrir une expérience utilisateur optimale en mettant en avant les prestations et services spécialisés proposés par le centre, tout en intégrant une fonctionnalité de prise de rendez-vous en ligne pour simplifier l’accès aux soins auditifs. Maison Paramed s'engage à accompagner ses patients avec expertise et bienveillance pour une meilleure santé auditive."

  },

  {
    id: 3,
    name: "Opic - Social Media",
    category: "Social Media",
    image: portfolio_opic,
    carousel: [sm_opic_1, sm_opic_2, sm_opic_3, sm_opic_4, sm_opic_5, sm_opic_6, sm_opic_7, sm_opic_8, sm_opic_9]
  },
  {
    id: 4,
    name: "Audis - Social Media",
    category: "Social Media",
    image: sm_audis_7,
    carousel: [sm_audis_1, sm_audis_2, sm_audis_3, sm_audis_4, sm_audis_5, sm_audis_6, sm_audis_7, sm_audis_8, sm_audis_9, sm_audis_10, sm_audis_11, sm_audis_12]
  },
  {
    id: 5,
    name: "Chronovision Casa - Social Media",
    category: "Social Media",
    image: sm_chronocasa_1,
    carousel: [sm_chronocasa_1, sm_chronocasa_2, sm_chronocasa_3, sm_chronocasa_4, sm_chronocasa_5, sm_chronocasa_6, sm_chronocasa_7, sm_chronocasa_8, sm_chronocasa_9,]
  },
  {
    id: 6,
    name: "Opic - Présentation",
    category: "Présentation",
    image: present_opic,
    carousel: [sm_chronocasa_1, sm_chronocasa_2, sm_chronocasa_3, sm_chronocasa_4, sm_chronocasa_5, sm_chronocasa_6, sm_chronocasa_7, sm_chronocasa_8, sm_chronocasa_9]
  },
  {
    id: 7,
    name: "Essebar Architects",
    category: "Personal Branding",
    image: img_essebar1,
    carousel: [

      {
        videoUrl: video_essebar1,
        aspect: "portrait"
      },
      {
        videoUrl: video_essebar2,
        aspect: "portrait"
      },
      {
        videoUrl: video_essebar3,
        aspect: "portrait"
      },
    ]
  },
  {
    id: 8,
    name: "Maison Paramed - Social Media",
    category: "Social Media",
    image: sm_mpm_4,
    carousel: [sm_mpm_1, sm_mpm_2, sm_mpm_3, sm_mpm_4, sm_mpm_5, sm_mpm_6, sm_mpm_7, sm_mpm_8, sm_mpm_9, sm_mpm_10, sm_mpm_11, sm_mpm_12, sm_mpm_13, sm_mpm_14]
  },
  {
    id: 9,
    name: "Audis - Promotional Videos",
    category: "Audiovisuel",
    image: img_audis_1,
    carousel: [
      {
        videoUrl: video_audis_1,
        aspect: "portrait"
      },

    ]

  },
  {
    id: 10,
    name: "EvoMarket - Trend Videos",
    category: "Audiovisuel",
    image: img_evo_1,
    carousel: [
      {
        videoUrl: video_evo_1,
        aspect: "portrait"
      },

    ]

  },
  {
    id: 11,
    name: "ESGT - Graduation Event",
    category: "Event",
    image: img_esgt_1,
    carousel: [
      {
        videoUrl: video_esgt_1,
        aspect: "portrait"
      },

    ]

  },
  {
    id: 12,
    name: "Centre Dentaire Cherkaoui",
    category: "Developpement web",
    image: web_cherkaoui_long,
    listImages: [web_cherkaoui_c2, web_cherkaoui_c1, web_cherkaoui_c3, web_cherkaoui_c4, web_cherkaoui_c5],
    home: web_cherkaoui_long,
    bg: web_cherkaoui_long,
    website: "drcherkaouiabir.com/",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web de Dr. Cherkaoui Abir, dentiste spécialisée à Rabat. Ce site a été pensé pour offrir une plateforme conviviale et informative, présentant les différents soins dentaires proposés par le cabinet, qu'il s'agisse d’esthétique dentaire, d’orthodontie ou de soins généraux. Une fonctionnalité de prise de rendez-vous en ligne a été intégrée pour simplifier l’accès aux consultations. Le site reflète l’engagement de Dr. Cherkaoui Abir à offrir des soins de qualité dans une ambiance professionnelle et chaleureuse."

  },
  {
    id: 13,
    name: "Aroma Luxe",
    category: "Developpement web",
    image: web_aromaluxe_long,
    listImages: [web_aromaluxe_1, web_aromaluxe_2, web_aromaluxe_3, web_aromaluxe_4, web_aromaluxe_5, web_aromaluxe_6],
    home: web_aromaluxe_long,
    bg: web_aromaluxe_long,
    website: "",
    features: ["Site Ecommerce", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Aroma Luxe, une boutique spécialisée dans la vente de parfums haut de gamme. Ce site a été conçu pour offrir une expérience utilisateur élégante et immersive, mettant en avant une large sélection de fragrances raffinées pour hommes, femmes et unisexes. Une interface intuitive permet aux clients de découvrir facilement les nouveautés, les collections exclusives et les meilleures ventes. Une fonctionnalité de commande en ligne, accompagnée de modes de livraison flexibles, a été intégrée pour simplifier l’expérience d’achat. Le site reflète l'engagement d’Aroma Luxe à offrir des produits de qualité, alliant luxe et authenticité."

  },
  {
    id: 14,
    name: "Hamad Electronics",
    category: "Developpement web",
    image: web_HamadElectro_long,
    listImages: [web_HamadElectro_1, web_HamadElectro_2, web_HamadElectro_3, web_HamadElectro_4, web_HamadElectro_5, web_HamadElectro_6],
    home: web_HamadElectro_long,
    bg: web_HamadElectro_long,
    website: "",
    features: ["Site Ecommerce", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web de Hamad Electronics, une boutique en ligne spécialisée dans la vente de produits électroniques. Ce site a été pensé pour offrir une expérience d'achat fluide et moderne, mettant en avant une large gamme de produits, allant des smartphones et ordinateurs portables aux accessoires connectés et appareils électroménagers. Une interface intuitive permet aux clients de naviguer facilement entre les catégories, de comparer les caractéristiques techniques et de profiter des meilleures offres. Une fonctionnalité de commande en ligne sécurisée, associée à plusieurs options de livraison rapide, a été intégrée pour répondre aux attentes des clients. Le site reflète l’engagement de Hamad Electronics à proposer des produits de qualité à des prix compétitifs, avec un service client irréprochable"

  },
  {
    id: 15,
    name: "Annakhil Travel Agency",
    category: "Developpement web",
    image: web_annakhil_long,
    listImages: [web_annakhil_1, web_annakhil_2, web_annakhil_3, web_annakhil_6, web_annakhil_4, web_annakhil_7],
    home: web_annakhil_long,
    bg: web_annakhil_long,
    website: "",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Annakhil Travel Agency, une agence spécialisée dans l’organisation de voyages et de circuits personnalisés. Ce site a été conçu pour offrir une plateforme moderne et accessible, mettant en valeur une sélection variée de destinations locales et internationales, adaptées à tous les types de voyageurs. Une interface intuitive permet aux visiteurs de découvrir les offres phares, de consulter les itinéraires détaillés et de réserver en ligne en toute simplicité. Le site reflète l’engagement d’Annakhil Travel Agency à fournir des services de qualité, combinant expertise, convivialité et passion du voyage."

  },
  {
    id: 16,
    name: "Paradidoucha",
    category: "Developpement web",
    image: web_para_long,
    listImages: [web_para_1, web_para_2, web_para_3, web_para_4, web_para_6, web_para_7, web_para_8, web_para_9],
    home: web_para_long,
    bg: web_para_long,
    website: "",
    features: ["Site Ecommerce", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Découvrez le tout nouveau site web de Paradidoucha, la parapharmacie qui prend soin de vous au quotidien. Pensé pour être à la fois élégant et pratique, ce site vous offre un accès simplifié à une large sélection de produits dédiés à la santé, à la beauté et au bien-être. Besoin de vos essentiels ou envie de nouveautés ? Quelques clics suffisent pour les ajouter à votre panier et choisir une livraison rapide. Avec Paradidoucha, chaque visite est une promesse de qualité et de confiance, à portée de main."

  },

]
export { services, partners, projects, testimonials, portfolio_assets };
