import placeholder1 from '../../assets/projects/thumbs/5.png';
import placeholder2 from '../../assets/projects/thumbs/7.png';
import placeholder3 from '../../assets/projects/thumbs/8.png';
import placeholder4 from '../../assets/projects/thumbs/9.png';
import placeholder5 from '../../assets/projects/thumbs/10.png';
import placeholder6 from '../../assets/projects/thumbs/1.png';
import placeholder7 from '../../assets/projects/thumbs/11.png';


const projects_finished = [
    {
        id: 1,
        name: "Opic Maroc",
        category: "Developpement web",
        image: placeholder6,
        field: "BTP Entreprise",
        bgColor: "#262626",
        link:"/webdev/1",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 2,
        name: "Centre Dentaire Cherkaoui",
        category: "Developpement web",
        image: placeholder1,
        field: "siteweb pour un centre dentaire", 
        bgColor: "#736049",
        link:"/webdev/12",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 7,
        name: "Paradidoucha",
        category: "Developpement web",
        image: placeholder7,
        field: "Vente des produit cosmétiques",
        bgColor: "#3E402D",
        link:"/webdev/16",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 3,
        name: "Maison Paramed",
        category: "Developpement web",
        image: placeholder2,
        field: "Centre de correction auditive",
        bgColor: "#F27127",
        link:"/webdev/2",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 4,
        name: "HamadElectronics",
        category: "Developpement web",
        image: placeholder4,
        field: "Siteweb Ecommerce d'électronique ",
        bgColor: "#368677",
        link:"/webdev/14",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 5,
        name: "AromaLuxe",
        category: "Developpement web",
        image: placeholder3,
        field: "Siteweb Ecommerce de vente des parfums",
        bgColor: "#9F66D9",
        link:"/webdev/13",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
    {
        id: 6,
        name: "Annakhil Travel Agency",
        category: "Developpement web",
        image: placeholder5,
        field: "Siteweb Ecommerce de vente des parfums",
        bgColor: "#F2B705",
        link:"/webdev/15",
        // listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
        // home: web_opic_home,
        // bg: web_opic_banner,
        website: "https://www.opicmaroc.com",
        features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
        description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web d’Opic Maroc, une entreprise spécialisée dans le BTP basée à Rabat. Ce site a été conçu pour refléter l’expertise et le savoir-faire d’Opic Maroc dans la réalisation de projets de construction et d’infrastructure. Il met en lumière leurs différentes prestations, leurs réalisations, ainsi que leur engagement envers l’innovation et la qualité. Un espace dédié permet également de faciliter les échanges avec les clients et partenaires, tout en renforçant leur présence digitale."
    },
]

export { projects_finished }