import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { projects } from "../utils/data";
import { FaCircleArrowRight } from "react-icons/fa6";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";

export default function TestCarousel() {
  return (
    <>
      <Swiper
        // slidesPerView={1}
        centeredSlides={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 3,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 1,
          }
        }}
         spaceBetween={40}
        // pagination={{
        //   type: "fraction",
        // }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        loop={true}
      >
        {projects.map((project, index) => (
          <SwiperSlide>
            <div
              data-aos="zoom-in-up"
              key={index}
              className=" mx-[60px] sm:mx-[20px] md:mx-[40px] lg:mx-0 flex flex-col gap-4 bg-customDark text-white  items-start p-2"
            >
              <img
                className=" w-full  h-[190px] bg-center pointer-events-none object-cover"
                src={project.image}
                alt={project.name}
              />
              <a
                href={project.lien}
                className="underline h-[64px] cursor-pointer text-customGold capitalize font-medium text-[15px] md:text-[17px] lgtext-[21px] "
              >
                {project.name}
              </a>

              <p>{project.category}</p>
              <a
                href={project.lien}
                className=" capitalize py-3 bg-customBlue justify-center items-center flex gap-2 text-[15px] border duration-200 transition-all border-customBlue text-white  w-full font-semibold"
              >
                découvrir <FaCircleArrowRight />
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
