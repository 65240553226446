import React from "react";
import per45 from "../../assets/percent/3.png";
import per30 from "../../assets/percent/2.png";
import per20 from "../../assets/percent/1.png";
import packs from "../../utils/pricing";

function Packs() {
  return (
    <div className="m-auto my-3xl bg-gray-900 text-white min-h-screen py-10 px-11">
      <h1 className="text-3xl font-bold text-center mb-8">
        Nos Packs de Développement Web
      </h1>
      <div className="grid gap-3 lg:gap-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Pack Coup d’Envoi */}
        <div className="h-[800px] max-w-96 rounded-2xl shadow-lg flex flex-col overflow-hidden">
          <div className="min-h-[60px] rounded-t-2xl flex items-center justify-center"></div>

          <div className="bg-customBlue border border-customBlue shadow-lg p-6 rounded-2xl h-[700px]">
            <h2 className="text-xl font-semibold mb-4">Pack Coup d’Envoi</h2>
            <p className="text-gray-300 mb-6">
              Démarrez votre aventure en ligne avec style.
            </p>
            <p className="text-4xl font-bold mb-6">2499,00 DH</p>
            <div className="flex flex-row items-center mb-6">
              <p className="text-xl text-red-300 line-through font-bold mr-4">
                4499,00 DH
              </p>
              <img src={per20} alt="" className="w-12" />
            </div>
            <ul className="text-sm lg:text-base md::text-base flex-grow space-y-3 mb-6">
              <li>✅ 1 Page Professionnelle</li>
              <li>✅ Design Responsive</li>
              <li>✅ Formulaire de Contact Basique</li>
              <li>✅ Liens Réseaux Sociaux</li>
              <li>✅ Hébergement Inclus (1 an)</li>
              <li>❌ SEO Avancé: Non inclus</li>
              <li>❌ Multilingue: Non inclus</li>
            </ul>
            <div className="flex text-center shadow-lg rounded-sm bg-customGold hover:bg-customBlue hover:border-customGold hover:border-2 hover:text-white text-customDark py-1 lg:py-3 px-4 w-full">
              <a href="/pack/starterpack" className="w-full h-full ">
                Choisir ce pack
              </a>
            </div>
          </div>
        </div>

        {/* Pack En Pleine Croissance */}
        <div className="overflow-hidden h-[800px] max-w-96 rounded-2xl shadow-lg flex flex-col">
          <div className="border border-customGold min-h-[60px] bg-customGold rounded-t-2xl flex items-center justify-center">
            <h2 className="text-xl font-semibold text-customDark">
              le plus populaire
            </h2>
          </div>
          <div className="border border-customGold rounded-b-2xl p-6 h-[700px] bg-gray-800">
            <h2 className="text-xl font-semibold mb-4">
              Pack En Pleine Croissance
            </h2>
            <p className="text-gray-300 mb-6">
              Développez-vous avec confiance.
            </p>
            <p className="text-4xl font-bold mb-6">4499,00 DH</p>
            <div className="flex flex-row items-center mb-6">
              <p className="text-xl text-red-300 line-through font-bold mr-4">
                6499,00 DH
              </p>
              <img src={per30} alt="" className="w-12" />
            </div>
            <ul className="text-sm lg:text-base md::text-base flex-grow space-y-3 mb-6">
              <li>✅ Jusqu’à 5 Pages</li>
              <li>✅ Design Responsive</li>
              <li>✅ SEO Intermédiaire</li>
              <li>✅ Formulaires Avancés</li>
              <li>✅ Suivi des Visiteurs</li>
              <li>✅ Hébergement Inclus (1 an)</li>
              <li>✅ Assistance Technique (6 mois)</li>
              <li>❌ Boutique en Ligne: Non inclus</li>
            </ul>
            <div className="flex text-center shadow-lg rounded-sm bg-customGold hover:bg-customBlue hover:border-customGold hover:border-2 hover:text-white text-customDark py-1 lg:py-3 px-4 w-full">
              <a href="/pack/growthpack" className="w-full h-full ">
                Choisir ce pack
              </a>
            </div>
          </div>
        </div>

        {/* Pack La Totale */}

        <div className=" h-[800px] max-w-96 rounded-2xl shadow-lg flex flex-col overflow-hidden">
          <div className="min-h-[60px] rounded-t-2xl hidden lg:flex "></div>

          <div className="bg-customBlue border border-customBlue p-6 rounded-2xl h-[700px]">
            <h2 className="text-xl font-semibold mb-4">Pack La Totale</h2>
            <p className="text-gray-300 mb-6">
              Personnalisation et performance ultimes.
            </p>
            <p className="text-4xl font-bold mb-6">7999,00 DH + </p>
            <div className="flex flex-row items-center mb-6">
              <p className="text-xl text-red-300 line-through font-bold mr-4">
                10499,00 DH
              </p>
              <img src={per45} alt="" className="w-12" />
            </div>

            <ul className="text-sm lg:text-base md::text-base flex-grow space-y-2 mb-3">
              <li>✅ Pages Illimitées</li>
              <li>✅ Design Optimisé</li>
              <li>✅ SEO Avancé</li>
              <li>✅ Boutique en Ligne</li>
              <li>✅ Maintenance 3 Mois</li>
              <li>✅ Multilingue</li>
              <li>✅ Hébergement Premium (1 an)</li>
              <li>✅ Assistance Technique (12 mois)</li>
              <li>✅ Intégration CRM</li>
              <li>✅ Intégration Blog</li>
            </ul>
            <div className="flex text-center shadow-lg rounded-sm bg-customGold hover:bg-customBlue hover:border-customGold hover:border-2 hover:text-white text-customDark py-1 lg:py-3 px-4 w-full">
              <a href="/pack/custompack" className="w-full h-full ">
                Choisir ce pack
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Packs;
