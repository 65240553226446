import per45 from "../assets/percent/3.png";
import per30 from "../assets/percent/2.png";
import per20 from "../assets/percent/1.png";

// const packs = [
//     {
//       id: "starterpack",
//       name: "Pack Coup d’Envoi",
//       description: "Démarrez votre aventure en ligne avec style.",
//       price: "2499,00 DH",
//       originalPrice: "4499,00 DH",
//       discountImage: per20,
//       features: [
//         "✅ 1 Page Professionnelle",
//         "✅ Design Responsive",
//         "✅ Formulaire de Contact Basique",
//         "✅ Liens Réseaux Sociaux",
//         "❌ SEO Avancé: Non inclus"
//       ],
//       link: "/pack"
//     },
//     {
//       id: "growthpack",
//       name: "Pack En Pleine Croissance",
//       description: "Développez-vous avec confiance.",
//       price: "4499,00 DH",
//       originalPrice: "6499,00 DH",
//       discountImage: per30,
//       features: [
//         "✅ Jusqu’à 5 Pages",
//         "✅ Design Responsive",
//         "✅ SEO Intermédiaire",
//         "✅ Formulaires Avancés",
//         "✅ Suivi des Visiteurs"
//       ],
//       link: "/pack"
//     },
//     {
//       id: "custompack",
//       name: "Pack La Totale",
//       description: "Personnalisation et performance ultimes.",
//       price: "7999,00 DH",
//       originalPrice: "10499,00 DH",
//       discountImage: per45,
//       features: [
//         "✅ Pages Illimitées",
//         "✅ Design Optimisé",
//         "✅ SEO Avancé",
//         "✅ Boutique en Ligne",
//         "✅ Maintenance 3 Mois"
//       ],
//       link: "/pack"
//     }
//   ];

const packs = [
  {
    id: "starterpack",
    name: "Pack Coup d’Envoi",
    description: "Démarrez votre aventure en ligne avec style.",
    price: "2499,00 DH",
    originalPrice: "4499,00 DH",
    discountImage: per20,
    features: [
      "✅ 1 Page Professionnelle",
      "✅ Design Responsive",
      "✅ Formulaire de Contact Basique",
      "✅ Liens Réseaux Sociaux",
      "✅ Hébergement Inclus (1 an)",
      "✅ Suivi Statistiques Basique",
      "❌ SEO Avancé: Non inclus",
      "❌ Multilingue: Non inclus"
    ],
    link: "/pack"
  },
  {
    id: "growthpack",
    name: "Pack En Pleine Croissance",
    description: "Développez-vous avec confiance.",
    price: "4499,00 DH",
    originalPrice: "6499,00 DH",
    discountImage: per30,
    features: [
      "✅ Jusqu’à 5 Pages",
      "✅ Design Responsive",
      "✅ SEO Intermédiaire",
      "✅ Formulaires Avancés",
      "✅ Suivi des Visiteurs",
      "✅ Hébergement Inclus (1 an)",
      "✅ Assistance Technique (6 mois)",
      "❌ Boutique en Ligne: Non inclus"
    ],
    link: "/pack"
  },
  {
    id: "custompack",
    name: "Pack La Totale",
    description: "Personnalisation et performance ultimes.",
    price: "7999,00 DH",
    originalPrice: "10499,00 DH",
    discountImage: per45,
    features: [
      "✅ Pages Illimitées",
      "✅ Design Optimisé",
      "✅ SEO Avancé",
      "✅ Boutique en Ligne",
      "✅ Maintenance 3 Mois",
      "✅ Multilingue",
      "✅ Hébergement Premium (1 an)",
      "✅ Assistance Technique (12 mois)",
      "✅ Intégration CRM",
      "✅ Intégration Blog"
    ],
    link: "/pack"
  }
];


export default packs;
