import React from "react";
import { ArrowUpRight } from "lucide-react";
import { projects_finished } from "./projectsFinished";
// Finished Projects Section
const FinishedProjectsSection = () => {
  return (
    <div className="px-8 md:px-0 py-16 bg-gray-200">
      <div className="container mx-auto">
        <h2 className="text-2xl font-thin mb-4">nos projets</h2>
        <h2 className="text-6xl text-customDark font-bold mb-8">terminés </h2>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {projects_finished.map((item, index) => (
            <ProjectCard
              key={index}
              name={item.name}
              image={item.image}
              field={item.field}
              bgColor={item.bgColor}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Project Card Component
const ProjectCard = ({ name, image, field, bgColor, link }) => {
  return (
    <div
      className="group shadow-md rounded-3xl overflow-hidden"
      style={{ backgroundColor: bgColor }}
    >
      {/* <div className={`bg-[#262626] shadow-md rounded-3xl overflow-hidden`}> */}
      <div className="relative h-80 md:h-[460px] w-full rounded-b-3xl overflow-hidden">
        <img
          src={image}
          alt="Project Thumbnail"
          className="object-cover w-full h-full shadow-lg transition duration-500 ease-in-out group-hover:scale-110 "
        />
      </div>
      <div className="px-8 py-8 flex flex-col justify-between">
        <div>
          <h3 className="text-xl font-bold text-white">{name}</h3>
          <p className="text-sm mb-4 text-white">{field}</p>
        </div>
        <a
          href={link}
          className="w-fit px-4 py-2 rounded-full flex items-center justify-center border-1 border-customGold border group-hover:bg-customDark group-hover:shadow-xl group-hover:border-0 transition duration-500 ease-in-out text-white"
        >
          <span className="font-light">View Project</span>
          <ArrowUpRight
            size={16}
            className="text-customGold ml-2 transform transition-transform duration-500 group-hover:rotate-45"
          />
        </a>
      </div>
    </div>
  );
};

const FinishedProjectsPage = () => {
  return (
    <div>
      <FinishedProjectsSection />
    </div>
  );
};

export default FinishedProjectsPage;
