import React from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import per20 from "../../assets/percent/1.png";
import packs from "../../utils/pricing";

const CartPack = () => {
  const { id } = useParams(); // Get the pack ID from the URL
  const navigate = useNavigate(); // Initialize useNavigate
  // Find the pack based on the ID
  const selectedPack = packs.find((pack) => pack.id === id);
  const handleContinue = () => {
    navigate("/sendpack", { state: { pack: selectedPack } }); // Navigate with data
  };
  return (
    <div className="p-4 bg-gray-50 pt-[100px] min-h-screen ">
      {/* Title */}
      <div className="w-full py-16 ">
        <div className="flex flex-row items-center mb-4">
          <button onClick={() => navigate(-1)}>
            <FaRegArrowAltCircleLeft className="w-8 h-8 text-customBlue hover:text-customDark" />
          </button>
          <h1 className="text-2xl font-bold text-customBlue ml-6">Retour</h1>
        </div>

        {/* Responsive Container */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Section */}
          <div className="lg:w-2/3 p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">{selectedPack.name}</h2>
            <div className="flex justify-between items-center mb-4">
              <div className="lg:4/6 w-2/3">
                <p className="text-gray-300 mb-6">{selectedPack.description}</p>
                <ul className=" flex-grow space-y-3 mb-6">
                  {selectedPack.features.map((item, index) => (
                    <li className="text-xs lg:text-base" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-right">
                <p className="text-xl md:text-4xl lg:text-4xl  font-bold mb-6">
                  {selectedPack.price}
                </p>
                <div className="flex flex-row items-center mb-6">
                  <p className="text-sm md:text-4xl lg:text-4xl text-red-300 line-through font-bold mr-4">
                    {selectedPack.originalPrice}
                  </p>
                  <img
                    src={selectedPack.discountImage}
                    alt=""
                    className="w-12"
                  />
                </div>
              </div>
            </div>
            <p className="text-green-600 bg-green-100 p-2 rounded">
              Excellente nouvelle ! Vous obtenez un{" "}
              <span className="font-bold">maintenance GRATUIT</span> pour 3 mois
              avec cette commande.
            </p>
          </div>

          {/* Right Section */}
          <div className="lg:w-1/3 p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Sous-total</h2>
            <div className="flex justify-between items-center mb-4">
              <p className="text-gray-500 line-through">
                {selectedPack.originalPrice}
              </p>
              <p className="text-xl font-bold text-black">
                {selectedPack.price}
              </p>
            </div>
            <div className="flex justify-between items-center mb-4">
              <p className="text-gray-600">Remise du pack</p>
              <p className="text-green-600">- 2000,00 DH</p>
            </div>
            <div className="mb-4">
              <p className="text-customBlue cursor-pointer">
                Vous avez un code de réduction ?
              </p>
              <div className="flex mt-2">
                <input
                  type="text"
                  placeholder="Saisir un code de réduction"
                  className="border rounded-l px-3 py-2 w-full"
                />
                <button className="bg-gray-500 text-white px-4 rounded-r">
                  Appliquer
                </button>
              </div>
            </div>
            <button
              onClick={handleContinue}
              className="w-full bg-customGold text-customBlue py-2 rounded"
            >
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPack;
