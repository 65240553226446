import React from "react";
import Packs from "./Packs";
import FinishedProjectsPage from "./FinishedProjects";

function ContainerProjectsIT() {
  return (
    <div className="relative pt-[100px] bg-gray-900">
      <FinishedProjectsPage />
      <Packs />
    </div>
  );
}

export default ContainerProjectsIT;
