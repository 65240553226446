import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Services from '../pages/Services'
import ConseilStrategie from '../pages/ConseilStrategie'
import WebDigital from '../pages/WebDigital'
import GraphiqueDesign from '../pages/GraphiqueDesign'
import OpicDetails from '../pages/OpicDetails'
import Agence from '../pages/Agence'
import Contact from '../pages/Contact'
import Portfolio from '../pages/Portfolio'
import SocialMediaPorfolio from '../pages/SocialMediaPorfolio'
import WebDevPortfolio from '../pages/WebDevPortfolio'
import PresentationPortfolio from '../pages/PresentationPortfolio'
import PersonalBranding from '../pages/PersonalBranding'
import Packs from '../pages/pricing/Packs'
import CartPack from '../pages/pricing/CartPack'
import FormPack from '../pages/pricing/FormPack'
import ContainerProjectsIT from '../pages/pricing/ContainerProjectsIT'
import Case from '../pages/pricing/Case'


const Routers = () => {
  return (

    <Routes>
      <Route index element={<Home />} />
      <Route path='services' element={<Services />} />
      <Route path='conseil-strategie' element={<ConseilStrategie />} />
      <Route path='digital-web' element={<WebDigital />} />
      <Route path='graphique-design' element={<GraphiqueDesign />} />
      <Route path='opic-details' element={<OpicDetails />} />
      <Route path='a-propos' element={<Agence />} />
      <Route path='contact' element={<Contact />} />
      <Route path='portfolio' element={<Portfolio />} />
      <Route path='socialmedia/:id' element={<SocialMediaPorfolio />} />
      <Route path='webdev/:id' element={<WebDevPortfolio />} />
      <Route path='audiovisuel/:id' element={<PersonalBranding />} />
      <Route path='presentation/:id' element={<PresentationPortfolio />} />
      <Route path='personalbranding/:id' element={<PersonalBranding />} />
      <Route path='event/:id' element={<PersonalBranding />} />
      <Route path='pricing' element={<ContainerProjectsIT />} />
      <Route path='case/:link' element={<Case />} />
      <Route path='pack/:id' element={<CartPack />} />
      <Route path='sendpack' element={<FormPack />} />

    </Routes>



  )
}

export default Routers