import React, { useState } from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const FormPack = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneCode: "+212",
    phoneNumber: "",
    country: "Maroc",
    city: "",
    workField: "",
    websiteCategory: "Sites Web Informatifs",
  });

  const API_KEY = "96b646e6-7dc6-4694-9535-9040f097e2d4"; // Replace with your actual API key
  const API_URL = "https://evomarketagency.com/api/evomarket/";

  const { pack } = location.state || {}; // Retrieve the data passed from CartPack

  const handleChange = (e) => {
    const { name, value } = e.target;
    // const updatedData = { [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Use the value of `name` as the key
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      phone: `${formData.phoneCode}${formData.phoneNumber}`,
      country: formData.country,
      city: formData.city,
      workfield: formData.workField,
      category: formData.websiteCategory,
    };


    try {
      // Attach the API key as a query parameter to the URL
      const urlWithApiKey = `${API_URL}?api_key=${API_KEY}`;

      // Send the POST request with the payload
      const response = await axios.post(urlWithApiKey, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      alert("Form submitted successfully!");
    } catch (error) {
      if (error.response) {
        alert("Failed to submit the form: " + error.response.data.message);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response from server:", error.request);
        alert("No response from the server. Please try again.");
      } else {
        // Something happened in setting up the request
        console.error("Error setting up the request:", error.message);
        alert("An error occurred. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!pack) {
    return (
      <div className="p-4 bg-gray-50 mt-[100px] min-h-screen text-center">
        <h1 className="text-2xl font-bold text-red-500">Aucune donnée reçue</h1>
        <button
          onClick={() => navigate(-1)}
          className="mt-4 text-customBlue underline"
        >
          Retour
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 mt-[100px] bg-gray-50 min-h-screen">
      <div className="flex flex-row items-center mb-4">
        <button onClick={() => navigate(-1)}>
          <FaRegArrowAltCircleLeft className="w-8 h-8 text-customBlue hover:text-customDark" />
        </button>
        <h1 className="text-2xl font-bold text-customBlue ml-6">Retour</h1>
      </div>
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="lg:w-4/6 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-customDark mb-4">
            Informations Générales
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">Prénom</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2"
                  placeholder="Entrez votre prénom"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">Nom</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2"
                  placeholder="Entrez votre nom"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-4">
              <div className="w-1/3">
                <label className="block text-sm font-medium mb-1">
                  Téléphone
                </label>
                <select
                  name="phoneCode"
                  value={formData.phoneCode}
                  onChange={handleChange}
                  className="w-full text-sm border rounded px-3 py-2"
                >
                  <option>+212 (Maroc)</option>
                  {/* Add other countries */}
                </select>
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1">Numéro</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2"
                  placeholder="Entrez votre numéro"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Pays</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full text-sm border rounded px-3 py-2"
              >
                <option>Maroc</option>
                {/* Add other countries */}
              </select>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Ville</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2"
                  placeholder="Entrez votre ville"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Domaine de travail
                </label>
                <input
                  type="text"
                  name="workField"
                  value={formData.workField}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2"
                  placeholder="Entrez votre domaine"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                Catégorie de siteweb
              </label>
              <select
                name="websiteCategory"
                value={formData.websiteCategory}
                onChange={handleChange}
                className="w-full text-sm border rounded px-3 py-2"
              >
                <option>Sites Web Informatifs</option>
                <option>Sites Web d'Entreprise/Corporate</option>
                <option>Sites Web de Commerce Électronique</option>
                <option>Sites Web de Réseaux Sociaux</option>
                <option>Sites Web de Divertissement</option>
                <option>Sites Web de Portfolio</option>
                <option>Sites Web Éducatifs</option>
                <option>Sites Web Gouvernementaux</option>
                <option>Sites Web d'Organisations à But Non Lucratif</option>
                <option>Forums et Sites Communautaires</option>
                <option>Sites Web Personnels</option>
                <option>Sites Web d'Actualités et Médias</option>
                <option>Pages de Destination</option>
                <option>Sites Web SaaS (Software as a Service)</option>
                <option>Sites Web d'Abonnement ou d'Adhésion</option>
                <option>Sites Web de Jeux Vidéo</option>
              </select>
            </div>

            {/* <button
              type="submit"
              className="w-full bg-customBlue text-white py-2 rounded mt-4"
            >
              Envoyer
            </button> */}
            <button className="w-full bg-customGold text-customBlue py-2 rounded">
              {isSubmitting ? "En cours..." : "Envoyer"}
            </button>
          </form>
        </div>
        {/* Order Summary */}
        {/* Keep Order Summary as is */}
        {/* Order Summary */}
        <div className="lg:w-2/6 bg-white rounded-lg shadow-md p-6 text-customDark">
          <h2 className="text-xl font-bold mb-4">Résumé de la commande</h2>
          <div className="space-y-4">
            {/* Plan Details */}
            <div className="text-sm flex justify-between">
              <p className="">Création</p>
              <div className="flex">
                <p className="font-normal mr-2 line-through text-gray-500">
                  {pack.originalPrice}
                </p>
                <p className="font-bold">{pack.price}</p>
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-sm">Hébergement web</p>
              <div className="flex">
                <p className="font-normal mr-2 line-through text-gray-500">
                  600,00 DH
                </p>
                <p className="font-bold">0,00 DH</p>
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-sm">Plan de 24 mois</p>

              <div className="flex">
                <p className="font-normal mr-2 line-through text-gray-500">
                  600,00 DH
                </p>
                <p className="text-green-600">0.00 DH</p>
              </div>
            </div>

            <div className="flex justify-between">
              <p className="text-sm">Nom de domaine</p>
              <div className="flex">
                <p className="font-normal mr-2 line-through text-gray-500">
                  100,00 DH
                </p>
                <p className="text-green-600">0.00 DH</p>
              </div>
            </div>

            <div className="flex justify-between">
              <p className="text-sm">Protection de la vie privée</p>
              <p className="text-green-600">0.00 DH</p>
            </div>

            {/* Subtotal */}
            <div className="flex justify-between font-semibold">
              <p>Sous-total</p>
              <div className="flex">
                <p className="font-normal mr-2 line-through text-gray-500">
                  {pack.originalPrice}
                </p>
                <p className="">{pack.price}</p>
              </div>
            </div>
            <div className="flex justify-between text-green-600">
              <p>Remise</p>
              <p>- 2000,00 DH</p>
            </div>

            {/* Coupon Code */}
            <div>
              <p className="text-customBlue cursor-pointer mb-2">
                Vous avez un code de réduction ?
              </p>
              <div className="flex">
                <input
                  type="text"
                  placeholder="Entrez un code de réduction"
                  className="border rounded-l px-3 py-2 w-full"
                />
                <button className="bg-gray-500 text-white px-4 rounded-r">
                  Appliquer
                </button>
              </div>
            </div>

            {/* Estimated Total */}
            <div className="flex justify-between font-bold text-lg">
              <p>Total estimé</p>
              <p>{pack.price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPack;
