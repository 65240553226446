import CursorFollow from "./components/CursorFollow";
import LayOut from "./pages/LayOut";

function App() {
  return (
    <div>
      {/* <CursorFollow /> */}
      <LayOut />
    </div>
  );
}

export default App;
